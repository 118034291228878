import { Storage } from "./storage";
import { Modal  } from "bootstrap";

let cart = [];
let filterDOM = [];
let strenght = [];
const contentCon = document.querySelector('.list-item');



class UI {
    
    pagination() {
        let paging = document.querySelector('.pagination');
        
        if (paging) {
            let links = [...paging.querySelectorAll('a')];
            links.forEach(a => {
                a.addEventListener('click', e => {
                    let page = e.target.dataset.page
                    console.log(page);
                    // this.fetchCon(e.target.getAttribute('page'));
                })
            });
        }
    }
    tagPagination() {
        let paging = document.querySelector('.pagination');
        if (paging !== null) {
            let hyperlinks = [...paging.querySelectorAll('a')];
            hyperlinks.forEach(a => {
                a.addEventListener('click', e => {
                    this.tagsContent(e.target.getAttribute('page'));
                })
            });
        }
    }

    toggleCart() {
        $('#cart-info span.shopping-cart').on('click', function() {
            $('#cart').toggleClass('show-cart');
        })
    }
    async logicUI() {
        const contentCons = document.querySelector('#product-wrapper');
        
        $('.filter_selector').on('click', () => {
            this.fetchCon(0);
        });
        
        $(contentCons).on('change', '.ordernow', event => {
            if (event.target.checked) {
                this.addCartItem(event);
                this.inputStatus();
            } else {
                let id = event.target.value;
                this.removeItem(id);
                this.showCart();
                this.inputStatus();
            }
        })
        $('button.clear').on('click', () => {
            this.clearCart();
        });
        
        $('.cart-item').on('click', '.del-cart-item', event => {
            
            event.preventDefault();
            cart = Storage.getCart();
            let itemRemoveCart = event.target;
            let id = itemRemoveCart.dataset.id;
            this.removeItem(id);
            this.cartValue();
            this.emptyItem(id);
            this.inputStatus();
            this.getSingleInput(id);
        });
    }
    addCartItem(event) {
        if (event.target.checked) {
            let item = event.target.parentElement.parentElement.parentElement.parentElement;
            let ItemCart = {}
            let prod_id = event.target.value;
            let prod_name = event.target.getAttribute('ref');
            let prod_img = event.target.getAttribute('img');
            let prod_price = event.target.getAttribute('price');
            let amount = 1;
            ItemCart = { prod_id, prod_name, prod_img, prod_price, amount }
            cart = [...cart, ItemCart];
            Storage.saveCart(cart);
            this.showCart();
            this.cartValue();
        }
    }
    removeItem(id) {
        cart = cart.filter(item => item.prod_id !== id);
        Storage.saveCart(cart);
        this.cartValue();
    }
    emptyItem(id) {
        $('#block-' + id).remove();
    }
    async showCart() {
        const cartContent = document.querySelector('#cart-items');
        const cartInfo = document.querySelector('#cart-infos');
        
        // Add more container elements if needed
        const cartContainers = [cartContent, cartInfo];  // An array of elements to append to
        
        const cart = await Storage.getCart();  // Await async operation if necessary
    
        // Clear content of each container before appending new content
        cartContainers.forEach(container => container.innerHTML = '');
    
        cart.forEach(item => {
            const div = document.createElement('div');
            div.id = "block-" + item.prod_id;
            div.classList.add('card', 'mb-2');
            div.innerHTML = `
                <div class="row g-0">
                    <div class="col-6">
                        <img src="${item.prod_img}" class="img-fluid rounded-start w-75" alt="${item.prod_name}">
                    </div>
                    <div class="col-6">
                        <button data-id="${item.prod_id}" class="btn btn-danger btn-sm del-cart-item">
                            <span class="fas fa-trash-alt trash-icon" data-id="${item.prod_id}"></span>
                        </button>
                    </div>
                </div>
                <div class="row">
                        <div class="card-body p-2">
                            <h6 class="card-title text-wrap">${item.prod_name}</h6>
                            <div class="row card-text">
                                <div class="col-md-6">฿ ${parseFloat(item.prod_price).toFixed(2)}</div>
                                <div class="col-md-6"><input type="number" min="1" name="qty" value="${item.amount}" class="qty form-control form-control-sm" id="qty-${item.prod_id}"></div>
                            </div>
                    </div>
                </div>
            `;
    
            // Append the div to each container in cartContainers
            cartContainers.forEach(container => container.appendChild(div.cloneNode(true)));
        });
    
        return false;
    }
    
    
    CartQTY() {
        const cartContent = document.querySelector('.cart-item');
        cart = Storage.getCart();
        cartContent.addEventListener('change', event => {
            let cartItems = event.target;
            let ItemId = cartItems.id;
            let id = ItemId.slice(4, 10);
            let amount = cartItems.value;
            let tempItem = cart.find(item => item.prod_id === id);
            tempItem.amount = amount;
            if (amount > 0) {
                Storage.saveCart(cart);
            } else {
                this.removeItem(id);
            }
        })
    }
    inputStatus() {
        let inputs = [...document.querySelectorAll('.ordernow')];
        
        inputs.forEach(input => {
            let ids = input.getAttribute('id');
            let id = ids.slice(8, 20);
            let inCart = cart.find(item => item.prod_id === id);
            if (inCart) {
                input.checked = true;
            } else {
                input.checked = false;
            }
        });
    }
    cartValue() {
        cart = Storage.getCart();
        let no_item = cart.length;
        if (no_item > 0) {
            $('.item-added').text(no_item);
            $('#cart-info').addClass('show-cart-info')
            $('#cart-info').removeClass('cart-hide');
            $('#cart').addClass('show-cart')
            $('#cart').removeClass('cart-hide');
        } else if (no_item <= 0) {
            $('.item-added').text('ไม่มี');
            $('#cart-info').addClass('cart-hide');
            $('#cart-info').removeClass('show-cart-info');
            $('#cart').addClass('cart-hide');
            $('#cart').removeClass('show-cart');
        }
    }
    clearCart() {
        const cartContent = document.querySelector('.cart-item');
        const cartInfo = document.querySelector('#cart-infos');
        cart = Storage.getCart();
        let cartItems = cart.map(item => item.prod_id);
        cartItems.forEach(prod_id => this.removeItem(prod_id));
        
        while (cartContent.children.length > 0) {
            cartContent.removeChild(cartContent.children[0]);
        }
        while (cartInfo.children.length > 0) {
            cartInfo.removeChild(cartInfo.children[0]);
        }
        this.cartValue();
        this.inputStatus();
    }
    getSingleInput(id) {
        let inputID = $('input#aviable-' + id);
        inputID.prop('checked', false);
    }
    quote(){
        const quoteBtn = document.querySelectorAll('.btn-quote')
        quoteBtn.forEach(btn => {
            btn.addEventListener('click', event => {
                event.preventDefault();
                $('#contactModal').appendTo("body").modal('show');
            })
        })
    }
    checkout(){
        const checkoutBtn = document.querySelector('.checkout')
        checkoutBtn.addEventListener('click',(e)=>{
            e.preventDefault()
            $('#checkoutModal').appendTo('body').modal('show')
        })
    }

    
        
}
export {UI}