import { UI } from "./ui";
import { Pagination } from "./pagination";
const ui = new UI();

class Product {
    async showProducts(products,currentPage){
        const itemperpage = 6
        let page = currentPage ? currentPage:1
        let startIndex = (page-1)*itemperpage
        let endIndex = startIndex+itemperpage
        let currentItems = products.slice(startIndex,endIndex)
        let totalItems = products.length
        const container = document.getElementById('product-wrapper')
        container && (container.innerHTML = '');
        if (container) {
        container.innerHTML = currentItems.map(mix=>`
        <div class="col d-flex align-items-stretch">
            <div class="card"   itemscope itemtype="http://schema.org/Product">
                
                <meta itemprop="sku" content="${mix.prod_sku}" />
                <meta itemprop="name" content="${mix.prod_name}" />
                <link itemprop="image" href="/assets/images/contents/${mix.prod_img}" />
                <meta itemprop="description" content="${mix.prod_name} เป็นคอนกรีต${mix.concrete_type_name} สามารถรองรับน้ำหนักได้ไม่ต่ำกว่า${mix.prod_strength}กิโลกรัมต่อพื้นที่ 1 ตร.ซม. มีค่ายุบตัวคอนกรีตสดที่ ${mix.prod_slump} ซม. " />
                    
                <div class="img-hover240 position-relative">
                    <a class="noneunderline " itemprop="url" href="/bangkok/${mix.prod_url}/${mix.prod_name.replace(/[\s\.]/g,'-')}" title="${mix.prod_name}">
                        <div class="d-flex align-items-top"  style="height:205px;max-height:205px;">
                            <img class="img-fluid object-fit-cover w-100" itemprop="image" src="/assets/images/contents/${mix.prod_img}" alt="${mix.prod_name}"/>
                            <span class="opacity-25"></span>
                        </div>
                        <span class="watermark-logo d-flex align-items-center justify-content-center"><img src="/assets/images/contents/${mix.brand_logo}" width="32" height="32" alt="${mix.brand_name}" /></span>
                    </a>
                </div>

                <div class="card-body">
                    <a href="/bangkok/${mix.prod_url}/${mix.prod_name.replace(/[\s\.]/g,'-')}" title="${mix.prod_name}">
                        <div class="card-title">
                            <h4 class="row"><span itemprop="name">${mix.prod_name}</span></h4>
                        </div>
                    </a>
                    <div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
                        <meta itemprop="ratingValue" content="${mix.prod_rating}">
                        <meta itemprop="worstRating" content="0">
                        <meta itemprop="bestRating" content="5">
                        <meta itemprop="reviewCount" content="1">
                        
                    </div> 
                    <div class="card-text brand">
                        <span itemprop="brand">${mix.brand_name}</span>
                        <span class="star-rating text-warning" itemprop="rating" itemscope itemtype="http://schema.org/Rating">
                            ${this.generateStars(parseFloat(mix.prod_rating))}
                        </span>
                    </div>
                    <div class="offer-block" itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">         
                        <meta itemprop="offerCount" content="6" />
                        <meta itemprop="priceCurrency" content="THB" />
                        <meta itemprop="availability" content="http://schema.org/InStock" />
                        <span class="text-decoration-line-through" itemprop="highPrice" content="${mix.prod_price_list.toFixed(2)}">${new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(mix.prod_price_list)}</span>
                        <span class="fw-bold text-danger fs-3" itemprop="lowPrice" content="${mix.prod_price.toFixed(2)}">${new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(mix.prod_price)}</span>
                    </div>
                    <div class="card-text">

                        <link itemprop="availability" href="http://schema.org/InStock" />
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"><span>ขอราคาถูกกว่านี้   คลิก !</span></li>
                            <li class="list-group-item">
                                <div class="form-check">
                                    <input type="checkbox"  name="avialable-${mix.prod_id}" 
                                        id="aviable-${mix.prod_id}" value="${mix.prod_id}" 
                                        class="ordernow order form-check-input" ref="${mix.prod_name}" 
                                        img="/assets/images/contents/${mix.prod_img}" 
                                        price="${mix.prod_price}">
                                    <label for="aviable-${mix.prod_id}"
                                            class="form-check-label"> ซื้อเลย - พร้อมส่ง</label>
                                <div>

                            </li>
                            <li class="list-group-item">
                                <div class="form-check">
                                    <input type="checkbox" name="compare-${mix.prod_id}" 
                                        value="${mix.prod_id}" 
                                        id="compare-${mix.prod_id}" 
                                        class="compare add form-check-input" >
                                    <label for="compare-${mix.prod_id}" 
                                        class="form-check-label" > เปรียบเทียบ</label>
                                </div>
                            </li>
                        </ul>
                        <meta itemprop="priceCurrency" content="THB" />
                    </div>
                    
                    <div class="card-text">
                        ${mix.prod_strenght > 0 ? `<span><i class="bi bi-box-fill"></i>${mix.prod_strenght} ksc.cu </span>`:``}
                        ${mix.prod_slump ? `<span><i class="bi bi-cone"></i> ${mix.prod_slump} ซม.</span>`:``}
                    </div>
                </div>
            </div>
        </div>
        `).join('')
        }
        if (document.querySelector('.paginate')) {
            document.querySelector('.paginate').innerHTML = await Pagination.paginate(totalItems,itemperpage,page,'#')
        }
        this.getPage(products);
        ui.inputStatus();
    }    

    filterProducts(products, filters) {
        return products.filter(product => {
            return Object.keys(filters).every(key => {
                if (!filters[key]) return true; // Skip filtering if the filter is falsy
                if (Array.isArray(filters[key])) { // Check if it's an array
                    return filters[key].includes(product[key]); // Include if the product's key is in the filter array
                }
                return product[key] === filters[key]; // Exact match for non-array filters
            });
        });
    }

    async getPage(products) {
        let paging = document.querySelector('.pagination');
        ui.inputStatus();
        if (paging) {
            let anchors = [...paging.querySelectorAll('a')];
            anchors.forEach(anchor => {
                anchor.addEventListener('click', e => {
                    let page = e.target.dataset.page
                    this.showProducts(products,page)                    
                })
            });
        }
    }
    
    async getProduct(){

        try {
            const response = await fetch('/metros/api/allMixes')
            const json = await response.json()
            return json
        } catch (error) {
            console.log(error);
        }


    }

    numPages(total,adjacent){
        return Math.ceil(total/adjacent)
    }

    generateStars(rating) {
        let starsHtml = '';
        for (let i = 1; i <= 5; i++) {
            if (i <= Math.floor(rating)) {
                starsHtml += '<span class="bi bi-star-fill"></span>';
            } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
                starsHtml += '<span class="bi bi-star-half"></span>';
            } else {
                starsHtml += '<span class="bi bi-star"></span>';
            }
        }
        return starsHtml;
    }

    applyFiltersAndDisplay(products, filters, currentPages) {
        const filteredProducts = this.filterProducts(products, filters);
        this.showProducts(filteredProducts, currentPages);
    }
}

export { Product}